.event-popup {
  width: 650px;
  height: 550px;
  border-radius: 25px;
  background: #F8FFFF;
  box-shadow: 0px 15px 20px rgba(86, 20, 20, 0.3);
  /* transition: width 2s, height 4s; */
  font-family: "Poppins", sans-serif;
}
.cross-btn{
  float: right;
  margin: 1rem;
  cursor: pointer;
}
.account-head{
  margin: 3rem 3rem 24px 3rem;
  font-size: 16px;
}
.attach-image{
  margin: 1rem 2rem 24px 3rem;
  font-size: 16px;
}
.detail{
  display: flex;
}
.account-details{
  margin-left: 3rem;
  font-size: 16px;
}
.account-number{
  margin-left: 3rem;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.35);
}
.account-number p{
  margin-bottom: 15px;
}
.account-details p{
  margin-bottom: 15px;
}
.screenshot{
width: 250px;
height: 110px;
margin-left: 3rem;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
font-size: 13px;
background: #FFFFFF;
box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
border-radius: 5px;
padding: 10px;
cursor: pointer;
}
.screenshot p{
  text-align: center;
  padding: 10px;
}
.confirm{
background: #D21285;
border-radius: 30px;
color: white;
border: none;
padding: 10px 5px;
width: 135px
}

.cancel{
border-radius: 30px;
border: 1px solid #D21285;
padding: 10px 5px;
width: 135px;
background-color: transparent;
margin-left: 2rem;
}

.buttons{
  margin-left: 3rem;
  margin-top: 2rem;
}


@media (max-width: 400px) {
  .event-popup {
    width: 344px;
    height: 480px;
  }
  .account-details{
    margin-left: 2rem;
  }
.account-details p{
  font-size: 10px;
}
.account-head{
  margin-left: 2rem;
  font-size: 14px;
}
.account-number p{
  font-size: 10px;
}
.screenshot{
  width: 190px;
  height: 80px;
  margin-left: 2rem;
}
.attach-image{
  font-size: 14px;
  margin-left: 2rem;
}
.upload{
  font-size: 12px;
}
.confirm{
  width: 100px;
  padding: 7px 5px;
}
.cancel{
  width: 100px;
  margin-left: 1rem;
  padding: 7px 5px;
}
.buttons{
  margin-left: 2rem;
}
}
