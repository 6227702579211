.container {
  display: flex;
  font-family: "Poppins", sans-serif;
}
.image-side {
  width: 50%;
  height: 100vh;
  overflow: hidden;
}
.form-page {
  width: 50%;
  height: 100vh;
  overflow: hidden;
}
.image-side img {
  width: 100%;
  height: 100%;
}
.main-form {
  background: linear-gradient(
    180deg,
    rgba(140, 3, 14, 0.1) 0%,
    rgba(140, 3, 14, 0) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 30px;
  width: 60%;
  height: auto;
  min-height: 540px;
  margin: 50px auto;
}
.form {
  width: 73%;
  /* height: 200px; */
  padding-top: 20px;
  margin: auto;
}
.main-form h2 {
  text-align: center;

  font-size: 20px;

  padding-top: 28px;
  color: #d21285;
  font-weight: bold;
}
.form div input[type="radio"] {
  margin-left: 5px;
}
.fields {
  margin-top: 7px;
  margin-bottom: 5px;
  padding: 10px;
  width: 94%;
  border: 2px solid #d21285;
  border-radius: 50px;
}
.form p {
  color: #676767;
  font-size: 13px;
  margin-top: 15px;
}
.form p span {
  color: #d21285;
  font-weight: bold;
  cursor: pointer;
}
.form p span:hover {
  text-decoration: underline;
}
.btn {
  background: #d21285;
  border-radius: 50px;
  width: 100%;
  padding: 11px;
  color: #ffffff;
  margin-top: 30px;
  cursor: pointer;
}
.form input ::placeholder {
  color: rgba(0, 0, 0, 0.2);
}
@media (max-width: 900px) {
  .main-form h2 {
    font-size: 15px;
  }
  .image-side {
    display: none;
  }
  .form-page {
    width: 100%;
  }
  .main-form {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
  }
  .form {
    width: 84%;
  }
}
