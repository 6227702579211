.review-card {
  /* background-color: aqua; */
  width: 78%;
  margin: auto;
  font-family: "Poppins", sans-serif;
  margin-bottom: 4rem;
}
.question1 {
  border-bottom: 2px solid black;
}

.questions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.questions p {
  width: 70%;
  font-weight: bold;
  padding-top: 2rem;
  padding-bottom: 1rem;
  color: #88165a;
}
.remove-icon {
  cursor: pointer;
}
.answer {
  margin-bottom: 2rem;
  color: rgba(0, 0, 0, 0.35);
  /* transition: width 600ms ease-out, height 600ms ease-out; */
  animation: fadeIn 1s forwards, slideUp 1s forwards;
}
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.box {
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

@media screen and (max-width: 900px) {
  .review-card {
    width: 80%;
  }
}
@media (max-width: 370px) {
  .review-card {
    width: 80%;
  }
}
