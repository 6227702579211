.reset-password-main{
    /* height: 320px; */
    width: 350px;
    background: linear-gradient(
        180deg,
        rgba(140, 3, 14, 0.1) 0%,
        rgba(140, 3, 14, 0) 100%
      );
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 30px;
      padding: 10px;

}

.reset-password-main h2{
   margin-left: 2rem;
   margin-top: 1rem;
   font-size: 20px;
}
.reset-password-main p{
    margin-left: 2rem;
    /* margin-top: 1rem; */
    margin-right: 2rem ;
}
.reset-password-main input{
    padding: 10px;
    width: 70%;
    margin-left: 2rem;
    margin-top: 1rem;
    border-radius: 8px;
    border: none;
}
.reset{
    width: 45%;
    padding: 8px;
  border: none;
  background-color: #d21285;
  color: white;
  border-radius: 8px;
  margin-top: 1rem;
  margin-left: 2rem;
  cursor: pointer;
  margin-bottom: 10px;
}