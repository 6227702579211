.sub-details3 select {
  padding: 10px;
  width: 90%;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
}

.h2 {
  margin-left: 2rem;
  margin-top: 2rem;
  color: #d21285;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 400px) {
  .sub-details3 select {
    width: 90%;
  }
}
