.footer-container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 17%,
    rgba(210, 142, 142, 1) 100%
  );
}

.footer {
  height: 200px;
  display: flex;
  font-family: "Poppins", sans-serif;
}
.footer-section1 {
  /* background-color: aqua; */
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-section1 p {
  color: black;
  font-size: 20px;
}
.footer-section2 {
  /* background-color: black; */
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.text2 {
  margin-left: 1rem;
  color: black;
  text-decoration: none;
}

.footer2 {
  /* background-color: aqua; */
  height: 70px;
  border: 1px solid rgba(76, 44, 89, 0.3);
  display: flex;
}
.lastline {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 2rem;
  /* background-color: aqua; */
  width: 50%;
  color: black;
}
.lastline2 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.tag {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.copyright {
  margin-left: 9px;
  padding-bottom: 5px;
}

@media (max-width: 900px) {
  .footer {
    flex-wrap: wrap;
    height: auto;
  }
  .footer-section1 {
    width: 100%;
  }
  .footer-section2 {
    width: 100%;
  }
  .footer2 {
    /* flex-wrap: wrap; */
    height: auto;
    justify-content: space-around;
  }
  .lastline {
    width: 100%;
    /* justify-content: center; */
  }
  .lastline2 {
    width: 100%;
  }
}

@media (max-width: 390px) {
  .tag p {
    font-size: 10px;
  }
  .copyright {
    font-size: 10px;
  }
  .lastline {
    margin-left: 1rem;
  }
  .footer-section1 p {
    font-size: 16px;
  }
}
