.main-container {
  margin-top: 55px;
  background-color: #de787866;
  width: 100%;
  position: relative;
}

.about-topbarIMG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 85px;
  margin-top: -5px;
}

.about-bottombarIMG {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65px;
}

.about-containerParent {
  padding-top: 100px;
  margin: auto;
  width: 75%;
  display: flex;
  flex-direction: row;
}

.about-containerChild1 {
  width: 65%;
}

.about-containerChild2 {
  width: 35%;
  display: flex;
  flex-direction: row-reverse;
}

.about-heading {
  padding-top: 6px;
  color: #c5221f;
  font-weight: 200;
  font-size: 33px;
  font-family: "Alata", sans-serif;
  text-align: center;
}

.about-paragraph {
  padding-top: 10px;
  font-size: 20px;
  font-family: "Alata", sans-serif;
  text-align: center;
  margin-bottom: 100px;
}

.about-coupleIMG {
  margin-top: -65px;
  left: 0;
  width: 380px;
  height: 120%;
}

/* Media queries for tablets */
@media (max-width: 1024px) {
  .about-containerParent {
    width: 90%;
  }
  .main-container {
    margin-top: 30px;
  }

  .about-topbarIMG,
  .about-bottombarIMG {
    height: 60px;
  }

  .about-containerParent {
    padding-top: 50px;
  }

  .about-containerChild1,
  .about-containerChild2 {
    width: 100%;
  }

  .about-coupleIMG {
    margin-top: 0;
    height: auto;
  }

  .about-heading {
    font-size: 28px;
  }

  .about-paragraph {
    font-size: 18px;
  }
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
  .main-container {
    margin-top: 30px;
  }
  .about-heading {
    padding-top: 20px;
  }
  .about-paragraph {
    margin: 0;
  }
  .about-containerParent {
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    width: 90%;
  }

  .about-containerChild1,
  .about-containerChild2 {
    width: 100%;
  }

  .about-coupleIMG {
    margin: auto;
    height: auto;
    width: 100%;
  }
}
