/* .social-linkHeader {
  width: 100%;
}

.social-linkParent {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 25px;
}
.social-linkChild1 {
  width: 50%;
}
.social-linkChild2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.social-linkHeading {
  padding-top: 50px;
  color: #d21285;
  font-weight: bold;
  margin-left: 1rem;
  font-size: 25px;
  font-weight: bold;
}

.social-details {
  padding-top: 20px;
  padding-left: 7px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.ancTAG2 {
  color: #50cc5d;
  font-size: 18px;
  cursor: pointer;
}
.social-detailsChild1 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1877f2;
  font-size: 18px;
  cursor: pointer;
}
.ancTAG1 {
  color: #1877f2;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
}
.social-detailsChild2 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #50cc5d;
  font-size: 18px;
  cursor: pointer;
}
.ancTAG2 {
  color: #50cc5d;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
}
.detailsChildError {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 900px) {
  .all-imgs {
    flex-wrap: wrap;
  }
  .video label {
    width: 80%;
  }
  .save {
    width: 30%;
    margin-bottom: 1rem;
  }
  .cnic {
    width: 60%;
  }
}

@media (max-width: 400px) {
  .user-pics h3 {
    margin-top: 1rem;
  }
  .all-imgs {
    width: 87%;
  }
} */

/* Base Styles */

.social-linkHeader {
  width: 100%;
}

.social-linkParent {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 25px;
}

.social-linkChild1 {
  width: 50%;
}

.social-linkChild2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.social-linkLogo {
  margin-top: -40px;
}
.social-linkHeading {
  padding-top: 50px;
  color: #d21285;
  font-weight: bold;
  margin-left: 1rem;
  font-size: 25px;
}

.social-details {
  padding-top: 20px;
  padding-left: 7px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ancTAG2,
.ancTAG1 {
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
}

.social-detailsChild1,
.social-detailsChild2 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}

.social-detailsChild1 {
  color: #1877f2;
}

.social-detailsChild2 {
  color: #50cc5d;
}

.detailsChildError {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 18px;
  text-align: center;
}

/* Media Queries */

@media (max-width: 900px) {
  .social-linkParent {
    flex-direction: column;
    align-items: center;
  }

  .social-linkChild1 {
    width: 100%;
  }
  .social-linkChild2 {
    width: 100%;
  }
  .social-linkLogo {
    margin: 0;
  }
  .social-linkHeading {
    padding-top: 20px;
  }

  .social-details {
    flex-direction: column;
    align-items: center;
  }

  .ancTAG1,
  .ancTAG2,
  .detailsChildError {
    width: 100%;
  }
  .social-detailsChild2 {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 18px;
    cursor: pointer;
  }
  .social-detailsChild1 {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 18px;
    cursor: pointer;
  }
}

@media (max-width: 400px) {
  .user-pics h3 {
    margin-top: 1rem;
  }

  .all-imgs {
    width: 87%;
  }
}
