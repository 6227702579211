.forget-password-main{
    height: 300px;
    width: 350px;
    background: linear-gradient(
        180deg,
        rgba(140, 3, 14, 0.1) 0%,
        rgba(140, 3, 14, 0) 100%
      );
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 30px;
      padding: 10px;
}
.forget-password-text{
    padding: 15px;
    margin-top: 2rem;
    margin-left: 1rem;
    font-size: 18px;
    font-weight: bold;
}
.forget-password-main input{
    padding: 10px;
    width: 80%;
    border-radius: 10px;
    border: none;
    margin-left: 2rem;
}
.link-text{
    padding: 0 10px;
    margin-left: 1.5rem ;
    margin-top: 10px;
}
.sent-btn{
    width: 60%;
    padding: 10px 5px;
    border: none;
    margin-left: 2rem;
    margin-top: 2rem;
    cursor: pointer;
    color: white;
    border-radius: 12px;
    background-color: #d21285;
}
.error-text{
    color: red;
    font-weight: bold;
    margin-left: 2rem;
    padding-top: 5px;
    
}

@media (max-width : 400px){
    .forget-password-main{
        width: 292px;
    }
}