* {
  margin: 0;
  padding: 0;
}

.container-1 {
  display: flex;
  font-family: "Poppins", sans-serif;
}
.image-side-1 {
  width: 50%;
  height: 100vh;
  overflow: hidden;
}
.image-side-1 img {
  width: 100%;
  height: 100%;
}
.form-page1 {
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main-form1 {
  background: linear-gradient(
    180deg,
    rgba(140, 3, 14, 0.1) 0%,
    rgba(140, 3, 14, 0) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 30px;
  width: 60%;
  height: auto;
  margin: 100px auto;
}
.form-1 {
  width: 70%;
  padding-top: 20px;
  margin: auto auto auto auto;
}
.checkboxes {
  display: flex;
  align-items: center;
}
.checkboxes label {
  margin-right: 6px;
}
.checkboxes input {
  margin-right: 1rem;
}
.main-form1 h2 {
  text-align: center;

  font-size: 25px;

  padding-top: 32px;
  color: #d21285;
}
.form-input {
  margin-top: 15px;

  padding: 10px;
  width: 94%;
  border: 2px solid #d21285;
  border-radius: 50px;
}
.line-checkbox {
  display: flex;
}
.line {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.line p {
  color: #d21285;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
}
.line p:hover {
  text-decoration: underline;
}
.btn {
  background: #d21285;
  border-radius: 50px;
  width: 100%;
  padding: 11px;
  color: #ffffff;
  margin-top: 30px;
  cursor: pointer;
  border: none;
}
.back-btn {
  height: 70px;
  width: 70px;
  fill: #d21285;
}
@media (max-width: 900px) {
  .main-form1 h2 {
    font-size: 15px;
  }
  .image-side-1 {
    display: none;
  }
  .form-page1 {
    width: 100%;
    /* display: block; */
    /* display: flex; */
  }
  .main-form1 {
    /* width: auto; */
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 400px) {
  .back-btn {
    height: 50px;
    width: 50px;
    float: right;
  }
  .main-form1 {
    width: 310px;
  }
}
