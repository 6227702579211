.outlet {
  width: 85%;
  overflow-y: scroll;
  height: 100vh;
}

@media (max-width: 900px) {
  .outlet {
    width: 100%;
  }
}
