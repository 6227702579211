.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: linear-gradient(
    180deg,
    rgba(210, 18, 133, 0.45) 0%,
    rgba(48, 213, 200, 0.45) 100%
  ); */
  background: rgb(255, 255, 255);
  background: linear-gradient(
    146deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(242, 201, 201, 1) 34%,
    rgba(123, 11, 8, 1) 92%
  );
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.splash-screen.hide {
  opacity: 0;
  pointer-events: none;
}

.logo {
  font-size: 3rem;
  color: #d9dcd6;
  animation: fade 2s ease-in-out;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
