.event-head h2 {
  color: #d21285;
  margin-left: 40px;
  margin-top: 2rem;
  font-size: 27px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.event-cards {
  margin-top: 1rem;
  display: grid;
  column-gap: 40px;
  row-gap: 40px;
  justify-items:center ;
  padding: 40px 30px 40px 30px;
  grid-template-columns: 320px 320px 320px;

  /* background-color: #ab0013; */
  /* padding-left: 30px;
  padding-right: 30px; */
  /* grid-template-columns: 315px 315px 315px; */
  /* grid-template-columns: auto auto auto; */
}
@media (max-width: 900px) {
  .event-cards {
    grid-template-columns: auto;
  }
}
