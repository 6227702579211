.img-card {
  height: 200px;
  width: 170px;
  border-radius: 10px;
  /* background-color: #d9d9d9; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.plus-icon {
  height: 80px;
  width: 80px;
  background: rgba(126, 134, 158, 0.25);
  font-size: 50px;
  display: flex;
  justify-content: center;
  border-radius: 47px;
  cursor: pointer;
}
.label {
  background: transparent;

  position: absolute;
}
@media (max-width: 900px) {
  .img-card {
    width: 147px;
    height: 70px;
    margin-bottom: 1rem;
  }
  .plus-icon {
    width: 50px;
    height: 50px;
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .img-card {
    width: 93px;
    height: 94px;
    margin-bottom: 1rem;
  }
}
