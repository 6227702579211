.swal2-title {
  color: #d21285;
  font-size: 28px;
  padding: 10px;
}

.select {
  margin-top: 10px;
  padding: 8px;
  width: 58%;
  border: 2px solid black;
}
