.landingMain-container {
  margin-top: 55px; /* ToCheck */
}
.landing-ImgDiv {
  display: flex;
  justify-content: center;
  width: 100%;
}
.landing-Image1 {
  height: 64%;
  width: 79%;
}
.landing-containerParent {
  margin: -35px auto auto auto;
  width: 78%;
  display: flex;
  flex-direction: row;
}
.landing-containerChild1 {
  width: 65%;
  margin: auto;
}
.landing-ImgDiv2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 13%;
}
.landing-Img1 {
  margin-top: -40px;
  padding-right: 20px;
  height: 120px;
  width: 83%;
}
.main-heading {
  display: flex;
  flex-direction: column;
}
.landing-heading1 {
  font-family: "Oooh Baby", cursive;
  font-size: 64px;
  font-weight: 200;
  text-align: center;
  padding-right: 72px;
  color: #710000;
}
.landing-heading2 {
  font-size: 58px;
  font-weight: 600;
  text-align: center;
  padding-right: 72px;
  color: #c2272d;
}
.landing-containerChild2 {
  width: 35%;
}
.landing-Img2 {
  margin-top: -8px;
  margin-left: 15px;
  height: 100%;
  width: 52%;
}

.landing-homeBar {
  width: 85%;
  height: 80px;
  margin: 12px auto auto auto;
  display: flex;
  flex-direction: row;
  font-size: 18px;
}

.landing-homeBarSpan1 {
  margin-top: 4px;
  background-color: #c7abae;
  width: 207px;
  height: 43px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0px 11px;
  border: none;
}
.landing-homeBarSpan2 {
  margin-top: 4px;
  margin-left: 24px;
  background-color: #c7abae;
  width: 70px;
  height: 43px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0px 11px;
  border: none;
}
.landing-homeBarSpan2Text {
  font-size: 18px;
  font-weight: normal;
  margin-left: 24px;
}
.landing-homeBarSpan3 {
  margin-top: 27px;
  margin-left: 28px;
  background-color: #c7abae;
  width: 100px;
  height: 38px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: black; */
  color: #7b0b08;
  font-weight: bold;
  cursor: pointer;
  border: 3px solid #7b0b08;
}
.landing-homeBarSpanNew {
  margin-top: 27px;
  margin-left: 28px;
  background-color: #c7abae;
  width: 100px;
  height: 43px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-homeBarSpan4 {
  margin-top: 4px;
  margin-left: 20px;
  background-color: #c7abae;
  width: 200px;
  height: 43px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0px 11px;
  border: none;
}

.landing-homebarmain {
  display: flex;
  flex-direction: column;
}

.new-fields {
  /* background-color: aliceblue; */
  display: flex;
  justify-content: center;
}

.field1 {
  padding: 0px 9px;
  font-size: 18px;
  width: 16%;
}

.field1 input {
  padding: 14px 10px;
  background-color: #c7abae;
  border-radius: 9px;
  border: none;
  width: 93%;
  /* margin: 5px; */
}

.field2 {
  padding: 0px 9px;
  font-size: 18px;
  width: 6%;
}

.field2 input {
  padding: 14px 10px;
  background-color: #c7abae;
  border-radius: 9px;
  border: none;
  width: 79%;
  /* margin: 5px; */
}

.find-btn {
  background-color: #c7abae;
  margin-top: 33px;
  width: 100%;
  border-radius: 7px;
  margin-left: 12px;
  color: #7b0b08;
  font-weight: bold;
  border: 2px solid #7b0b08;
  cursor: pointer;
  padding: 12px;
}

@media (max-width: 1078px) {
  .landing-homeBarSpan1 {
    width: 129px;
    height: 39px;
  }
  .landing-homeBarSpan4 {
    width: 129px;
    height: 39px;
  }
  .landing-homeBarSpan3 {
    width: 100px;
    height: 33px;
  }
  .landing-homeBarSpan2 {
    width: 70px;
    height: 40px;
  }
  .landing-homeBarSpanNew {
    width: 100px;
    height: 41px;
    margin-right: 23px;
  }
  .landing-homeBar {
    height: auto;
    flex-wrap: wrap;
  }
}

@media (max-width: 900px) {
  .new-fields {
    flex-wrap: wrap;
  }
  .field1 {
    width: 70%;
  }
  .field2 {
    width: 70%;
  }
  .find-btn {
    width: 100%;
    padding: 10px 0px;
  }
  .field2 input {
    width: 93%;
  }
  .find {
    width: 28%;
  }
}

@media (max-width: 768px) {
  .landingMain-container {
    margin-top: 80px; /* ToCheck */
  }
  .landing-Image1 {
    width: 90%;
  }
  .landing-containerParent {
    margin: auto;

    width: 90%;
    flex-direction: column;
  }
  .landing-containerChild1 {
    width: 100%;
  }
  .landing-ImgDiv2 {
    width: 100%;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-heading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .landing-heading1 {
    font-size: 50px;
    font-weight: 200;
    padding-right: 0;
    text-align: center;
  }
  .landing-heading2 {
    font-size: 55px;
    padding-right: 0;
    text-align: center;
  }

  .landing-Img1 {
    margin-top: -10px;
    padding-right: 35px;
    height: 90px;
    width: 80%;
  }
  .landing-containerChild2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .landing-Img2 {
    margin-top: 8px;
    height: 60%;
    width: 50%;
  }
  .landing-homeBarSpan2Text {
    margin-left: 0;
    margin-left: 23px;
  }
  .landing-homeBarSpan4 {
    margin-left: 0;
    margin-right: 19px;
  }
  .landing-homeBarSpan3 {
    margin-left: 4px;
  }
  /* .landing-homeBar {
    display: none;
  } */
}

@media (max-width: 460px) {
  .landing-homeBarSpanNew {
    margin-left: 0;
  }
}

@media (max-width: 400px) {
  .landingMain-container {
    margin-top: 80px; /* ToCheck */
  }
  .landing-Image1 {
    width: 98%;
  }
  .landing-containerChild1 {
    width: 100%;
  }
  .landing-containerChild2 {
    width: 100%;
  }
  .landing-Img1 {
    margin-top: -10px;
    padding-right: 35px;
    height: 90px;
    width: 90%;
  }
  .landing-homeBarSpan1 {
    width: 96%;
    margin-right: 2rem;
  }
  .landing-homeBarSpan2 {
    margin-left: 0;
    /* margin-right: 11px; */
    width: 100%;
  }
  .landing-homeBarSpan4 {
    width: 100%;
  }
  /* .landing-homeBarSpanNew {
    margin-left: 19px;
  } */
  .landing-homebarmain {
    margin-right: 2rem;
  }
  .landing-homeBarSpan2Text {
    margin-left: 0;
  }
  .landing-homeBar {
    width: 65%;
  }
  .landing-homeBarSpanNew {
    width: 121px;
  }
}
