.show-profile {
  color: #d21285;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.show-profile h2 {
  margin-top: 2rem;
  margin-left: 2rem;
}
.profile-img {
  height: 100px;
  width: 100px;
  margin-left: 2rem;
  margin-top: 1rem;
  border-radius: 20px;
}
.religion-details {
  color: #d21285;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.religion-details h3 {
  margin-left: 2rem;
  margin-top: 1rem;
}
.details1 {
  display: flex;
  margin-top: 1rem;
}
.details1 div {
  width: 50%;
  /* background-color: aqua; */
}
.sub-details {
  margin-left: 2rem;
}
.sub-details P {
  padding: 10px;
}
.sub-details3 input {
  padding: 9px;
  border-radius: 8px;
  margin-top: 4px;
  width: 83%;
}
.sub-details3 p {
  padding: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.sub-details3 label {
  margin-right: 12px;
  margin-left: 3px;
  margin-top: 2px;
}
.religion-details button {
  margin-left: 2rem;
  margin-top: 2rem;
  padding: 8px;
  width: 12%;
  border: none;
  background: linear-gradient(90deg, #d21285 0%, #30d5c8 100%);
  border-radius: 8px;
  color: white;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.test-detail {
  display: flex;
}

@media (max-width: 900px) {
  .details1 {
    flex-wrap: wrap;
  }
  .details1 div {
    width: 100%;
  }
  .religion-details button {
    width: 30%;
    margin-bottom: 1rem;
  }
  .test-detail {
    width: 100%;
  }
  .sub-details3 {
    margin-right: 1rem;
  }
  .sub-details3 input {
    width: 83%;
  }
}
