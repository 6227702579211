.user-pics h3 {
  color: #d21285;
  font-family: "Poppins", sans-serif;
  margin-left: 2rem;
  margin-top: 2rem;
}
.video h3 {
  color: #d21285;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin-left: 2rem;
  margin-top: 2rem;
}

.video label {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  border: 2px solid #d21285;
  width: 30%;
  margin-top: 1rem;
  padding: 5px;
  border-radius: 20px;
  color: #9c9c9c;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.save {
  padding: 9px;
  width: 15%;
  margin-top: 1rem;
  margin-left: 2rem;
  border-radius: 10px;
  background: linear-gradient(90deg, #d21285 0%, #30d5c8 100%);
  color: white;
  border: none;
  cursor: pointer;
  margin-bottom: 1rem;
}

.all-imgs {
  width: 90%;
  display: flex;
  /* justify-content: center; */
  margin-left: 2rem;
  margin-right: 2rem;
  /* justify-content: space-between; */
  margin-top: 1rem;
}
.cnic {
  display: flex;
  align-items: center;
  border: 2px solid #d21285;
  width: 30%;
  padding: 5px;
  border-radius: 8px;
  margin-left: 2rem;
  color: #d21285;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  /* margin-bottom: 1rem; */
  margin-top: 2rem;
}
.cnic p {
  padding-left: 7px;
}
.cnic label {
  padding-left: 7px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .all-imgs {
    flex-wrap: wrap;
  }
  .video label {
    width: 80%;
  }
  .save {
    width: 30%;
    margin-bottom: 1rem;
  }
  .cnic {
    width: 60%;
  }
}

@media (max-width: 400px) {
  .user-pics h3 {
    margin-top: 1rem;
  }
  .all-imgs {
    width: 87%;
  }
}
