.sub-card {
  height: 180px;
  width: 300px;
  color: white;
  border-radius: 25px;
  /* margin-left: 1rem;
  margin-top: 1rem; */
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 4px 10px;
  cursor: pointer;
}

.sub-card img {
  border-radius: 25px;
}
.event-address {
  color: white;
  position: absolute;
  bottom: 0;
  height: 70px;
  width: 100%;
  /* border-radius: 15px; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  justify-content: space-between;
  /* background-color: black; */
  background: linear-gradient(
    180deg,
    rgba(210, 18, 133, 0.1) 0%,
    rgba(48, 213, 200, 0.1) 100%
  );
  background-color: whitesmoke;
  color: black;
  font-weight: bold;
}

.add-text {
  padding: 0px 15px;
  margin-top: 1rem;
}

.address p {
  /* background-color: aqua; */
  padding-right: 15px;
  padding-top: 15px;
  font-weight: bold;
}


