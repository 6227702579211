.cnic-card{
    /* background-color: aqua; */
    height: 150px;
    width: 300px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
}

.cnic-cards{
    display: flex;
    margin-left: 2rem;
    height: 229px;
}

@media (max-width: 900px){
    .cnic-cards{
        flex-wrap: wrap;
    }
    .cnic-card{
        width: 250px;
    }
}