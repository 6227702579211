.main-Contt {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.main-Contt-Pic {
  width: 50%;
}

.main-Contt-Heading {
  margin-left: 3rem;
  color: #d21285;
  font-weight: bold;
}

.main-Contt-Remarks {
  width: 50%;
}

.main-Contt-Remarks h3 {
  margin-left: 2rem;
  margin-top: 1rem;
  color: #d21285;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.main-Contt-Remarks p {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 2rem;
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
  width: 94%;
  height: 113px;
  overflow-y: scroll;
  font-size: 15px;
}
.main-Contt-Remarks p::-webkit-scrollbar {
  width: 2px;
  background-color: #d21285;
  border-radius: 5px;
}
.main-Contt-Remarks p::-webkit-scrollbar-track {
  height: 1px;
}

.home-container {
  /* width: 85%; */
  overflow: hidden;
}
.bio h3 {
  margin-left: 2rem;
  margin-top: 1rem;
  color: #d21285;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.bio textarea {
  padding: 10px;
  border-radius: 10px;
  margin-left: 2rem;
  margin-top: 1rem;
  width: 90%;
}
.bio p {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 2rem;
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
  width: 94%;
  height: 113px;
  overflow-y: scroll;
  font-size: 15px;
}
.bio p::-webkit-scrollbar {
  width: 2px;
  background-color: #d21285;
  border-radius: 5px;
}
.bio p::-webkit-scrollbar-track {
  height: 1px;
}
.edit-btn {
  float: right;
  width: 10%;
  margin-right: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.edit-btn button {
  padding: 5px;
  width: 100%;
  background: linear-gradient(90deg, #d21285 0%, #30d5c8 100%);
  border-radius: 8px;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
.show-details {
  display: flex;
  margin-top: 2rem;
  font-family: "Poppins", sans-serif;
}
.show-details1 {
  width: 50%;
  display: flex;
}
.sub-details1 {
  width: 33%;
  margin-left: 2rem;
}
.sub-details2 {
  width: 33%;
  margin-left: 1rem;
}
.sub-details1 p {
  color: #8c030e;
  font-weight: bold;
  padding: 5px;
}
.sub-details2 p {
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  padding: 5px;
}
.sub-details2 input {
  padding: 5px;
  border-radius: 7px;
  margin-bottom: 3px;
  margin-top: 3px;
}
.select-height {
  display: flex;
  margin-left: 0px;
  width: 100%;
}

@media (max-width: 900px) {
  .main-Contt {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .main-Contt-Pic {
    width: 100%;
  }
  .main-Contt-Remarks {
    width: 100%;
  }
  .show-details {
    flex-wrap: wrap;
  }
  .show-details1 {
    width: 100%;
  }
  .sub-details1 {
    width: 38%;
  }
  .sub-details2 {
    width: 38%;
  }
  .edit-btn {
    width: 20%;
    margin-right: 1rem;
  }
}

@media (max-width: 400px) {
  .main-Contt {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .main-Contt-Pic {
    width: 100%;
  }
  .main-Contt-Remarks {
    width: 100%;
  }

  .bio textarea {
    width: 73%;
  }
  .show-details {
    display: block;
    margin-bottom: 2rem;
  }
  /* .show-details1 {
    margin-left: 1rem;
  } */
  .sub-details1 p {
    margin-left: 1rem;
  }
  .sub-details2 input {
    margin-left: 1rem;
    width: 70%;
    /* background-color: #d21285; */
  }
  .sub-details2 {
    width: 50%;
  }
  .bio p {
    height: 85px;
    width: 83%;
  }
  .select-height {
    width: 86%;
  }
}
