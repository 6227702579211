.upDate_navbar {
  background: white;
  color: black;
  height: 53px;
  font-family: "Poppins", sans-serif;
  position: fixed;
  width: 100%;
  z-index: 2;
  padding-bottom: 7px;
}

.upDate_Barr {
  background-color: #7b0b08;
  height: 23px;
}
.upDate_navbarContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.upDate_logoContainer {
  width: 55%;
}

.upDate_logo {
  width: 220px;
  height: 45px;
  margin: 6px 0 2px 20px;
}

.upDate_menuBar {
  list-style: none;
  display: flex;
  width: 43%;
  padding-right: 10%;
}
.upDate_menuBarItems {
  font-size: 19px;
  padding-top: 18px;
  padding-left: 20px;
  font-weight: bolder;
  width: auto;
  cursor: pointer;
}
.upDate_menuBarItems:hover {
  color: #7b0b08;
}
@media only screen and (max-width: 1000px) {
  .upDate_menuBar {
    display: none;
  }
}

.upDate_SigninButton {
  margin-left: 2rem;
  margin-top: 14px;
  width: 140px;
  font-size: 16px;
  padding: 8px;
  border-radius: 20px;
  background-color: #7b0b08;
  color: white;
  cursor: pointer;
  border: none;
}

@media (max-width: 1248px) {
  .upDate_menuBarItems {
    font-size: 14px;
  }
  .upDate_menuBar {
    width: 50%;
  }
}

@media only screen and (max-width: 1000px) {
  .signin-btn {
    display: none;
  }
}

.menu-container {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100vh;
  background-color: rgba(244, 208, 208, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  cursor: pointer;
}
.menu-container button {
  margin-top: 50px;
  width: 140px;
  font-size: 16px;
  padding: 8px;
  border-radius: 20px;
  background-color: #7b0b08;
  color: white;
  cursor: pointer;
  border: none;
}

.menu-container.open {
  right: 0;
}

@media only screen and (max-width: 1000px) {
  .signin-btn {
    display: none;
  }
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-item {
  margin: 1rem 0;
  text-align: center;
}

.menu-item span {
  color: #333;
  text-decoration: none;
  font-size: 1.5rem;
  transition: all 0.2s ease-in-out;
}

.menu-item span:hover {
  color: #d21285;
  font-weight: 900;
  font-size: 2rem;
  font-weight: bolder;
  transition: all 0.3s ease-in-out;
}

.menu-toggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.5rem;
  width: 2rem;
  position: relative;
}

.menu-toggle span {
  display: block;
  font-size: 20px;
  font-weight: bolder;
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-in-out;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  padding: 0px 10px 10px 10px;
  z-index: 3;
  margin-right: 10px;
  margin-top: 27px;
  position: absolute;
  right: 0;
}

.hamburger span {
  display: block;
  width: 30px;
  height: 3px;
  margin-bottom: 5px;
  background-color: black;
  transition: transform 0.3s ease-out;
}

.hamburger span:last-child {
  margin-bottom: 0;
}

.hamburger.active span:first-child {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:last-child {
  transform: translateY(-8px) rotate(-45deg);
}
@media only screen and (min-width: 1000px) {
  .hamburger {
    display: none;
  }
}
