.featureMain-container {
  margin-top: 55px;
  width: 100%;
  position: relative;
}
.features-bar {
  width: 55%;
  margin: 50px auto auto auto;
  border: 3px solid #d21212;
  border-radius: 50px;
  height: 40px;
  color: #881616;
}
.features-typer {
  padding-left: 20px;
  overflow: hidden;
  color: #881616;
}

.features-text {
  text-align: center;
  margin-top: 50px;
  padding-top: 40px;
  color: #c2272d;
  font-weight: 550;
  font-size: 46px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.features-containerParent {
  margin: auto;
  width: 75%;
  display: flex;
  flex-direction: row;
}

.features-containerChild1 {
  width: 75%;
  margin: auto;
  width: 60%;
  height: 250px;
  display: flex;
  flex-direction: row;
}

.features-containerChild2 {
  width: 40%;
  display: flex;
  flex-direction: row-reverse;
}

.features-IMG {
  margin-left: 5px;
  margin-top: 40px;
  height: 350px;
  width: 400px;
}
.features-sideCouple {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 70px;
  margin-top: 10px;
}

/* Media queries for tablets */
@media (max-width: 1024px) {
  .features-containerParent {
    width: 90%;
  }
  .features-text {
    margin-top: 20px;
    padding: 15px 15px 0px 15px;
    font-size: 40px;
  }
  .featureMain-container {
    margin-top: 30px;
  }

  .features-containerChild1 {
    display: flex;
    justify-content: center;
    width: 80%;
  }
  .features-containerChild2 {
    width: 100%;
  }
  .features-bar {
    width: 88%;
    height: 45px;
    color: #88165a;
    overflow: hidden;
    overflow-x: hidden;
  }
  .features-typer {
    width: 80%;
    position: absolute;
    padding: 0 10px 5px 40px;
    font-size: 13px;
    font-weight: 500;
    overflow: hidden;
    overflow-x: hidden;
    color: #88165a;
  }
  .features-IMG {
    margin: 90px auto auto auto;
    padding-top: 20px;
    height: 300px;
    width: 300px;
  }
  .features-sideCouple {
    display: none;
  }
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
  .featureMain-container {
    margin-top: 30px;
  }
  .features-text {
    margin-top: 20px;
    padding: 15px 15px 0px 15px;
    font-size: 40px;
  }

  .features-containerParent {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .features-containerChild1 {
    display: flex;
    justify-content: center;
  }
  .features-containerChild2 {
    width: 100%;
  }
  .features-bar {
    width: 88%;
    height: 45px;
    color: #88165a;
    overflow: hidden;
    overflow-x: hidden;
  }
  .features-typer {
    width: 80%;
    position: absolute;
    padding: 0 10px 5px 40px;
    font-size: 13px;
    font-weight: 500;
    overflow: hidden;
    overflow-x: hidden;
    color: #88165a;
  }
  .features-IMG {
    margin: 50px auto auto auto;
  }
  .features-sideCouple {
    display: none;
  }
}
