.otp-main{
    height: 320px;
    width: 350px;
    background: linear-gradient(
        180deg,
        rgba(140, 3, 14, 0.1) 0%,
        rgba(140, 3, 14, 0) 100%
      );
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 30px;
      padding: 10px;
}
.otp-main h1{
    font-size: 23px;
    margin-top: 1rem;
    margin-left: 2rem;
}
.otp-main p{
    /* margin-top: 1rem; */
    margin-left: 2rem;
    margin-right: 2rem;
    padding: 6px 0;
    text-align: center;
    font-size: 15px;
}
.otp-bars{
    margin: auto;
    width: 70%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.otp-bars input{
 padding: 5px;
 border: none;
 width: 100%;
 /* margin-top: 1rem; */
}
.otp-verify{
    padding: 5px;
    width: 50%;
    margin: auto;
    border: none;
    background-color: #d21285;
    color: white;
    border-radius: 7px;
    cursor: pointer;
}