.about2 {
  text-align: center;
  padding-top: 7rem;
  color: #d21285;
  font-family: "Poppins", sans-serif;
}
.aboutus {
  width: 80%;
  margin: auto;
}
.about {
  font-family: "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.6);
  padding: 15px;
  font-size: 17px;
}
.remarks {
  color: #d21285;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 3rem;
}

@media (max-width: 900px) {
  .remarks {
    font-size: 15px;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
