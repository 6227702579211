/* ...............Personal Details Form............... */
.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.upperSection {
  display: flex;
  flex-direction: row;
  margin: 5px;
  width: auto;
}

.sec1 {
  width: 50%;
}

.textArea {
  background-color: white;
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  resize: none;
  padding: 8px;
  font-family: "Poppins", sans-serif;
  margin: 15px 15px 15px 0px;
  width: 85%;
  padding-left: 10px;
  font-size: 15px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.mainDivFormm {
  width: auto;
  height: auto;
  padding: 0px 30px 30px 30px;
  background: linear-gradient(
    180deg,
    rgba(210, 18, 133, 0.1) 0%,
    rgba(48, 213, 200, 0.1) 100%
  );
  box-shadow: 0px 15px 20px rgba(86, 20, 20, 0.3);
  margin: auto;
  border-radius: 15px;
}
.mainDivForm {
  width: auto;
  height: auto;
  padding: 0px 30px 30px 30px;
  background: linear-gradient(
    180deg,
    rgba(210, 18, 133, 0.1) 0%,
    rgba(48, 213, 200, 0.1) 100%
  );
  box-shadow: 0px 15px 20px rgba(86, 20, 20, 0.3);
  margin: auto;
  border-radius: 15px;
}

.mainDivFormPersonal {
  width: auto;
  height: auto;
  padding: 0px 30px 30px 30px;
  background: linear-gradient(
    180deg,
    rgba(210, 18, 133, 0.1) 0%,
    rgba(48, 213, 200, 0.1) 100%
  );
  box-shadow: 0px 15px 20px rgba(86, 20, 20, 0.3);
  margin: auto;
  border-radius: 15px;
}

.mainDivForm h1 {
  color: #d21285;
  font-weight: bold;
  margin-left: 1rem;
  padding-top: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
}

.mainDivFormm h1 {
  color: #d21285;
  font-weight: bold;
  margin-left: 1rem;
  padding-top: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
}

.mainDivFormPersonal h1 {
  color: #d21285;
  font-weight: bold;
  margin-left: 1rem;
  padding-top: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
}

.maindivforDivision {
  display: flex;
  flex-direction: row;
}
.formSeperation {
  width: 350px;
  padding: 10px;
}

.inputMain {
  display: flex;
  flex-direction: column;
}

.formLabel {
  margin-left: 5px;
  width: 90%;
  font-weight: bold;
  padding: 0px 0px 2px 0px;
  font-size: 15px;
  color: #d21285;
}
.formNoLabel {
  margin-left: 5px;
  margin-top: 18px;
  width: 90%;
  font-weight: bold;
  padding: 0px 0px 2px 0px;
  font-size: 15px;
  color: #d21285;
}
.formInputNew {
  margin: 5px 0 5px 0;
  height: 37px;
  width: 90%;
  padding-left: 10px;
  font-size: 15px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.formSelect {
  margin: 5px 0 5px 0;
  width: 94%;
  font-size: 15px;
  border-radius: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.heightSelect {
  display: flex;
  align-items: center;
  width: 94%;
}
.buttonDiv {
  display: flex;
  justify-content: center;
  margin: 2px 0 2px 0;
}

.formButton {
  background: linear-gradient(90deg, #d21285 0%, #30d5c8 100%);
  border-radius: 8px;
  color: white;
  height: 36px;
  width: 200px;
  font-size: 15px;
  cursor: pointer;
  border: none;
}

@media screen and (max-width: 768px) {
  /* Mobile and Tablet Portrait */
  .formContainer {
    /* display: block; */
  }
  .mainDivFormPersonal {
    padding: 0px 15px 15px 15px;
    height: auto;
  }
  .mainDivFormm {
    padding: 0px 15px 15px 15px;
    /* height: 100%; */
  }
  .upperSection {
    flex-direction: column;
  }

  .sec1 {
    width: 100%;
  }

  .formSeperation {
    width: 100%;
  }

  .formInput {
    width: 95%;
  }
  .formSelect {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  /* Mobile Portrait */
  .formContainer {
    display: block;
  }
  .mainDivFormPersonal {
    padding: 0px 15px 15px 15px;
    height: auto;
  }
  .mainDivForm {
    height: 100%;
    padding: 0px 10px 10px 10px;
  }
  .formContainer {
    height: 100vh;
  }

  .formSeperation {
    width: auto;
  }

  .mainDivForm h1 {
    font-size: 22px;
    margin: 0;
    padding: 10px;
    text-align: center;
  }
  .maindivforDivision {
    flex-direction: column;
  }

  .formLabel {
    font-size: 13px;
  }

  .formInput {
    font-size: 13px;
    width: 93%;
  }
  .formSelect {
    font-size: 13px;
    width: 95.5%;
  }
}
