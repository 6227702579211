.navbar {
  background: rgb(226, 189, 213);
  background: linear-gradient(
    180deg,
    rgba(226, 189, 213, 1) 0%,
    rgba(226, 189, 213, 1) 35%,
    rgba(252, 254, 255, 1) 100%
  );
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins", sans-serif;
  height: 70px;
  position: fixed;
  width: 100%;
  z-index: 2;
  padding-bottom: 7px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
