.card {
  background: linear-gradient(180deg, #30d5c8 0%, rgba(48, 213, 200, 0) 100%);
  height: 250px;
  width: 300px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  flex-direction: column;
  color: #8c030e;
  transition: all 0.2s ease-in-out;
}
.card h2 {
  font-size: 50px;
}

.card:hover {
  background: #AC136D ;
  color: white;
  box-shadow: 5px 20px 40px #490000;
  transform: scale(1.1);
  z-index: 2;
}

@media (max-width: 900px) {
  .card {
    width: 100%;
    box-shadow: 0px 10px 30px rgba(76, 44, 89, 0.4);
    margin-bottom: 1rem;
  }
}
@media (max-width: 370px) {
  .card {
    box-shadow: 0px 10px 30px rgba(76, 44, 89, 0.4);
    margin-bottom: 1rem;
    height: 200px;
  }
}
