.event-loc {
  color: #d21285;
  font-weight: bold;
}
.event-loc h2 {
  margin-top: 2rem;
  margin-left: 2rem;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
}
.event-pic {
  height: 200px;
  width: 370px;
  border-radius: 15px;
  margin-left: 2rem;
  margin-top: 1rem;
}
.event-pic img {
  border-radius: 15px;
}
.set-date {
  color: #d21285;
  margin-left: 2rem;
  font-weight: bold;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  margin-top: 2rem;
 
}
.set-date p{
  font-size: 17px;
}
.date-text {
  margin-left: 3rem;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
}
.events-btn {
  margin-left: 2rem;
  margin-top: 1rem;
}

.btn1 {
  padding: 13px;
  width: 13%;
  margin-right: 7px;
  border: none;
  background: linear-gradient(90deg, #d21285 0%, #30d5c8 100%);
  color: white;
  border-radius: 7px;
  cursor: pointer;
}
.pending-btn{
  text-align: center;
  width: 12%;
  padding: 13px;
  margin-right: 7px;
  background-color: #80808052;
  border-radius: 7px;
}
.btn-cancel {
  border: 2px solid #d21285;
  padding: 13px;
  width: 13%;
  margin-right: 7px;
  color: #d21285;
  border-radius: 7px;
  cursor: pointer;
  margin-left: 2rem;
  background-color: white;
}
.main-popup {
  top: 0;
  height: 100vh;
  width: 85%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.14);
  backdrop-filter: blur(2.5px);
  transition: width 2s, height 4s;
}

@media (max-width: 900px) {
  .main-popup {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .event-pic {
    width: 302px;
    height: 170px;
  }
  .btn1 {
    width: 40%;
  }
  .main-popup {
    width: 100%;
  }
  .pending-btn{
    width: 29%;
  }
}
