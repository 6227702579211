.formInput {
  margin: 5px 0 5px 0;
  height: 37px;
  padding-left: 10px;
  font-size: 15px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 85%;
}
.social-heading {
  color: #d21285;
  font-size: 16px;
  font-weight: bold;
}

.confirm-btn {
  margin: 5px 0 5px 18px;
  background: #d21285;
  border-radius: 30px;
  color: white;
  border: none;
  padding: 5px;
  width: 15%;
  height: 37px;
  cursor: pointer;
}

.event-popup {
  width: 650px;
  height: 360px;
  border-radius: 25px;
  background: #f8ffff;
  box-shadow: 0px 15px 20px rgba(86, 20, 20, 0.3);
  font-family: "Poppins", sans-serif;
}
.cross-btn {
  float: right;
  margin: 1rem;
  cursor: pointer;
}
.account-head {
  margin: 3rem 3rem 24px 3rem;
  font-size: 16px;
}
.attach-image {
  margin: 1rem 2rem 24px 3rem;
  font-size: 16px;
}
.detail {
  display: flex;
  width: 100%;
}
.account-detailss {
  font-size: 16px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-detailsss {
  font-size: 16px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-number {
  width: 50%;
  font-size: 16px;
}
.account-number p {
  margin-bottom: 15px;
}
.account-details p {
  margin-bottom: 15px;
}
.screenshot {
  width: 250px;
  height: 110px;
  margin-left: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 13px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}
.screenshot p {
  text-align: center;
  padding: 10px;
}

.cancel {
  border-radius: 30px;
  border: 1px solid #d21285;
  padding: 10px 5px;
  width: 135px;
  background-color: transparent;
  margin-left: 2rem;
}

.buttons {
  margin-left: 3rem;
  margin-top: 2rem;
}

@media (max-width: 400px) {
  .event-popup {
    width: 344px;
    height: 480px;
  }
  .detail {
    flex-direction: column;
    width: 100%;
  }
  .account-detailss {
    padding-top: 40px;

    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .account-detailsss {
    padding-top: 40px;
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .account-details p {
    font-size: 10px;
  }
  .account-head {
    margin-left: 2rem;
    font-size: 14px;
  }
  .account-number p {
    font-size: 10px;
  }
  .screenshot {
    width: 190px;
    height: 80px;
    margin-left: 2rem;
  }
  .attach-image {
    font-size: 14px;
    margin-left: 2rem;
  }
  .upload {
    font-size: 12px;
  }
  .confirm {
    width: 100px;
    padding: 7px 5px;
  }
  .cancel {
    width: 100px;
    margin-left: 1rem;
    padding: 7px 5px;
  }
  .buttons {
    margin-left: 2rem;
  }
}
