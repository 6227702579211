.fade-in {
  opacity: 0;
  animation: fadeInAnimation 1s ease-in forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.update-Home {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}
.update-HomeImage1 {
  margin-top: 30px;
}
.update-HomeContainer {
  display: flex;
  flex-direction: row;
}
.update-HomeContainer1 {
  display: flex;
  flex-direction: column;
}
.update-HomeImage2 {
  margin-left: 205px;
  margin-top: -50px;
}
.upDate_mainPic {
  margin-top: -27px;
  margin-left: 18px;
}
.upDate_heading1 {
  font-family: "Oooh Baby", cursive;
  font-size: 64px;
  font-weight: 200;
  padding-right: 77px;
  color: #710000;
}

.upDate_heading2 {
  font-size: 60px;
  font-weight: bolder;
  font-weight: 600;
  padding-right: 10px;
  color: #c2272d;
  margin-top: -8px;
}

/* .upDate_homeBar {
  width: 85%;
  height: 80px;
  margin: 12px auto auto auto;
  display: flex;
  flex-direction: row;
  font-size: 18px;
}

.upDate_homeBarSpan1 {
  margin-top: 4px;
  background-color: #c7abae;
  width: 240px;
  height: 43px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.upDate_homeBarSpan2 {
  margin-top: 4px;
  margin-left: 24px;
  background-color: #c7abae;
  width: 80px;
  height: 43px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.upDate_homeBarSpan2text {
  font-size: 18px;
  font-weight: normal;
  margin-left: 24px;
}
.upDate_homeBarSpan3 {
  margin-top: 24px;
  margin-left: 28px;
  background-color: #c7abae;
  width: 100px;
  height: 43px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.upDate_homeBarSpan4 {
  margin-top: 4px;
  margin-left: 20px;
  background-color: #c7abae;
  width: 200px;
  height: 43px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
} */
/* .tagline {
  font-family: "Poppins", sans-serif;
  color: #88165a;
  margin-top: 7rem;
  font-size: 18px;
  font-weight: bold;
  padding-left: 70px;
} */
.text {
  color: white;
  font-size: 48px;
  font-weight: bolder;
  margin-left: 6.5rem;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #d21285;
}
.tagline2 {
  margin-top: 1rem;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;
}

.tagline2 span {
  text-align: center;
  /* color: linear-gradient(180deg, #d21285 0%, #30d5c8 100%); */
  font-size: 30px;
  font-weight: bold;
  color: #d21285;
  letter-spacing: 2px;
  position: relative;
}

.roller {
  height: 4.125rem;
  line-height: 4rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d3557;
}
.roller #rolltext {
  position: absolute;
  top: 0;
  animation: slide 5s infinite;
}

@keyframes slide {
  0% {
    top: 0;
  }
  25% {
    top: -4rem;
  }
  50% {
    top: -8rem;
  }
  72.5% {
    top: -12.25rem;
  }
}

.update-aboutSection {
  margin-top: 55px;
  background-color: #de787866;
  height: 450px;
  width: 100%;
}
.update-aboutcontainer {
  padding-top: 100px;
  margin: auto;
  width: 75%;
  height: 250px;
  display: flex;
  flex-direction: row;
}
.update-aboutcontainer1 {
  width: 65%;
}
.update-aboutcontainer2 {
  width: 35%;
  display: flex;
  flex-direction: row-reverse;
}
.update-abouttext {
  padding-top: 6px;
  color: #c5221f;
  font-weight: 200;
  font-size: 33px;
  font-family: "Alata", sans-serif;
  text-align: center;
}
.update-abouttext2 {
  padding-top: 10px;
  font-size: 20px;
  font-family: "Alata", sans-serif;
  text-align: center;
}
.update-featureSectionText {
  text-align: center;
  margin-top: 50px;
  padding-top: 40px;
  color: #c2272d;
  font-weight: 550;
  font-size: 46px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.update-featureSection {
  margin-top: 55px;
  height: 560px;
  width: 100%;
}
.update-featureSection2 {
  margin-top: 15px;
  height: 450px;
  width: 100%;
}
.update-featurecontainer {
  margin: auto;
  width: 75%;
  height: 250px;
  display: flex;
  flex-direction: row;
}
.update-featurecontainer1 {
  width: 50%;
  margin-left: 25px;
}
.update-featurecontainer2 {
  width: 50%;
}
.about-para {
  width: 80%;
  margin: auto;
  padding-top: 1rem;
  text-align: left;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
}
.about-textt {
  color: #d21285;
  font-size: 17px;
  padding-right: 12rem;
  cursor: pointer;
  font-weight: 400;
  text-align: right;
}
.about-textt:hover {
  font-size: 17px;
  font-weight: 400;
  text-decoration: underline;
  text-align: right;
}
.cards {
  display: flex;
  justify-content: center;
  box-shadow: 0px 10px 30px rgba(76, 44, 89, 0.4);
  width: 70%;
  height: 250px;
  margin: auto;
  margin-top: 4rem;
  margin-bottom: 3rem;
}
.update-featureSection {
  margin-top: 80px;
  margin-bottom: 10px;
}

.reviews {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.heading {
  text-align: center;
  padding-top: 20px;
  color: #c2272d;
  font-weight: 550;
  font-size: 46px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.profile {
  margin: auto;
  /* background-color: antiquewhite; */
  width: 27%;
  margin-bottom: 2rem;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
}

.profile::-webkit-scrollbar {
  width: 5px;
}

.profile::-webkit-scrollbar-thumb {
  background-color: #f70000;
}

@media (max-width: 900px) {
  .cards {
    flex-wrap: wrap;
  }
  .profile {
    width: 60%;
  }
  .tagline {
    padding-left: 11px;
    text-align: center;
    font-size: 15px;
  }
  .text {
    margin: 0;
    font-size: 30px;
  }

  .cards {
    display: none;
  }
}

@media (max-width: 400px) {
  .update-HomeContainer {
    display: flex;
    flex-direction: column;
  }
  .update-HomeImage2 {
    margin: 0;
    width: 90%;
  }
  .tagline {
    padding-left: 11px;
    font-size: 14px;
    text-align: center;
  }
  .text {
    margin: 0;
  }
  .tagline2 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .about-section h2 {
    font-size: 25px;
  }
  .cards {
    box-shadow: none;
    width: 75%;
  }
  .reviews {
    margin-top: 0px;
  }
  .feature-section {
    margin-bottom: 1rem;
  }
}

/* .bar-div {
  width: 55%;
  margin:50px auto auto auto  ;
  border-radius: 50px; 
  height: 40px;
  border: 6px solid #8075A7; 
  
} */

.aa {
  width: 55%;
  margin: 50px auto auto auto;
  border: 3px solid #d21212;
  border-radius: 50px;
  height: 40px;
  color: #881616;
}
.Typing {
  padding-left: 20px;
  overflow: hidden;
  color: #881616;
}

@media screen and (max-width: 900px) {
  .aa {
    width: 88%;
    height: 45px;
    color: #88165a;
    overflow: hidden;
    overflow-x: hidden;
  }
  .Typing {
    width: 80%;
    position: absolute;
    padding: 0 10px 5px 40px;
    font-size: 13px;
    font-weight: 500;
    overflow: hidden;
    overflow-x: hidden;
    color: #88165a;
  }
  .about-textt {
    color: #88165a;
    font-size: 17px;
    padding-right: 40px;
    padding-top: 20px;
    cursor: pointer;
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight: 400;
    text-align: right;
  }
  .cards {
    display: none;
  }
  .feature-section {
    margin-top: 2rem;
    /* margin-bottom: 2rem; */
  }
}

.svg-icon.search-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
}

/* .aa {
  float: left;
  width: 50%;
  background-color: #f70000;
}

@media screen and (max-width: 992px) {
  .aa {
    width: 80%;
    background-color: aqua;

  }
}
 */
/*  
 .animation-on-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.box {
  width: 100px;
  height: 100px;
  margin: 20px;
  opacity: 0;
}

.box-1 {
  animation: fadeIn 1s forwards, slideRight 1s forwards;
}

.box-2 {
  animation: fadeIn 1s forwards, slideLeft 1s forwards;
}

.box-3 {
  animation: fadeIn 1s forwards, slideUp 1s forwards;
}

.box-4 {
  animation: fadeIn 1s forwards, slideDown 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Adjust these values as needed */
/* .box {
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-timing-function: ease-out;
} */

.main {
  display: flex;
  margin-top: 2rem;
  justify-content: space-around;
}

.first {
  width: 40%;
  padding-left: 90px;

  /* background-color: antiquewhite; */
}
@media screen and (max-width: 900px) {
  .first {
    padding-left: 20px;
  }
}
.icon {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}
.icon-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 20px;
  margin-left: 2rem;
}

.second {
  width: 60%;
  padding-left: 90px;
  padding-right: 90px;

  height: 400px;
  /* background-color: aqua; */
}
@media screen and (max-width: 900px) {
  .second {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 900px) {
  .second {
    display: none;
  }
  .first {
    width: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (max-width: 370px) {
  .feature h2 {
    font-size: 25px;
  }
  .main {
    margin-top: 0px;
  }
}

@media (max-width: 400px) {
  .feature {
    height: 485px;
  }
}
