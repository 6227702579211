.user-img {
  /* background-color: aquamarine; */
  font-family: "Poppins", sans-serif;
  /* width: 50%;  */
}
.user-img h1 {
  color: #d21285;
  margin-left: 3rem;
  margin-top: 1rem;
}
.img {
  height: 120px;
  width: 120px;
  border-radius: 94px;
  background-color: bisque;
  border: 4px solid #d21285;
  margin-top: 1rem;
  position: relative;
}
.name {
  display: flex;
  /* background-color: black; */

  align-items: center;
  margin-left: 2rem;
}
.camera-icon {
  position: absolute;
  cursor: pointer;
  top: 5.5rem;
  bottom: 0;
  right: 0;
}

.name p {
  color: #d21285;
  font-weight: bold;
  margin-left: 1rem;
  font-size: 25px;
  font-weight: bold;
}

@media (max-width: 900px) {
  .user-img {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .img {
    height: 100px;
    width: 100px;
  }
  .camera-icon {
    top: 4.5rem;
  }
}
