.sidebar {
  width: 18%;
  background: linear-gradient(180deg, #d21285 0%, #30d5c8 100%);
  height: 100vh;
  font-family: "Poppins", sans-serif;
  transition: all 0.12s ease;
  border-top-right-radius: 25px;
}

.image {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  /* padding: 0px 30px 0px 30px; */
}
.list {
  color: white;
}
.list ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* text-align: center; */
  width: 96%;
  margin: auto;
}
.list ul li {
  list-style: none;
  text-decoration: none;
  padding: 10px 10px;
  margin: 0 1rem;
  font-size: 13px;
}
.list li:hover {
  background: linear-gradient(
    180deg,
    #d9d9d9 -42.5%,
    rgba(217, 217, 217, 0) 133.75%
  );
  border-radius: 30px;
  cursor: pointer;
}
.logout-btn {
  position: absolute;
  width: 15%;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.logout-btn button {
  padding: 8px;
  color: white;
  background-color: #d21285;
  width: 80%;
  cursor: pointer;
  border: none;
  border-radius: 10px;
}
.logout-btn button:hover {
  background: linear-gradient(180deg, #d21285 0%, #bbd5d3 100%);
}
.arrow {
  height: 100vh;
  display: none;
  z-index: 2;
}
.arrow-icon {
  cursor: pointer;
  background-color: #4d1640;
  border-radius: 0 12rem 12rem 0;
  width: 20px;
  height: 40px;
  fill: white;
}
.image-logo {
  margin-top: 1rem;
  height: 50px;
  width: 200px;
  transition: all 0.25s;
}

@media (max-width: 900px) {
  .arrow {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
  }
  .sidebar1 {
    background-color: aliceblue;
    width: 63%;
    animation: fadeIn 1s forwards, slideRight 1s forwards;
    font-size: 12px;
    position: fixed;
    z-index: 2;
    transition: all 0.25s;
  }
  @keyframes slideRight {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  .sidebar1 {
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }
  .hide-sidebar {
    display: none;
    transition: all 0.25s;
  }
  .image1 {
    height: 51px;
    width: 100px;
    margin-bottom: 1rem;
    transition: all 0.25s;
  }
  .logout-btn {
    width: 60%;
  }
}

@media (max-width: 400px) {
  .sidebar1 {
    width: 70%;
  }
  .logout-btn {
    bottom: 4rem;
  }
}
